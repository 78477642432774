<template>
  <div>
    <a-modal
      :visible="visible"
      title="编辑"
      :width="1400"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="6">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: item.name,
                    rules: [
                      { required: true, message: '请输入名称' },
                      { max: 64, message: '超出最大长度(64)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="生产线">
              <a-select
                v-decorator="[
                  'production_line',
                  {
                    initialValue: item.production_line,
                    rules: [{ required: true, message: '请选择生产线' }],
                  },
                ]"
                style="width: 100%"
                @change="onChangeProductionLine"
              >
                <a-select-option value="a">名创 A</a-select-option>
                <a-select-option value="b">名创 B</a-select-option>
                <a-select-option value="c">名创 C</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="默认道口">
              <CrossingSelect
                v-decorator="[
                  'default_crossing',
                  { initialValue: item.default_crossing, rules: [{ required: true, message: '请选择默认道口' }] },
                ]"
                :productionLine="item.production_line"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="备注">
              <a-input
                v-decorator="['remark', { initialValue: item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="分拣条件" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
              <SortingFilterTable
                v-decorator="['sorting_filter_items', { initialValue: item.sorting_filter_items }]"
                :productionLine="item.production_line"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { sortingRuleUpdate } from "@/apis/sorting";

export default {
  components: {
    CrossingSelect: () => import("@/components/CrossingSelect"),
    SortingFilterTable: () => import("@/components/SortingFilterTable"),
  },
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
      productionLine: undefined,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          sortingRuleUpdate({ id: this.item.id, ...values })
            .then((data) => {
              this.$emit("update", data);
              this.$message.success("编辑成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
    onChangeProductionLine(value) {
      this.productionLine = value;
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
